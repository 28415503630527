import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux';
import { base_url, client_name, helmet_url, postHeader } from '../../../Helpers/request';
import BannerSlider from './Components/Bannerslider';
import ShopByDiamondShape from './Components/ShopByDiamondShape';
import ExploreCollection from './Components/ExploreCollection';
import DesignDiamondRing from './Components/DesignDiamondRing';
import Customer from './Components/Customer';
import Insta from './Components/insta';
import SignupTo from './Components/SignupTo';
import ShopRingByStyle from './Components/ShopRingByStyle';
import GemstoneGlamour from './Components/GemstoneGlamour';
import CustomDesign from './Components/CustomDesign';
import ConsultSpecialist from './Components/ConsultSpecialist';
import Promises from './Components/Promises';
import { Button, Container, Form, Image, InputGroup, Modal } from 'react-bootstrap';
import DesktopPopup from "../../../Assets/images/spruce-diamond-popup-desktop.jpg"
import MobilePopup from "../../../Assets/images/spruce-diamond-popup-mobile.jpg"
import axios from 'axios';
import swal from 'sweetalert';
import ExploreHolidayCollection from './Components/ExploreHolidayCollection';
import Snowfall from 'react-snowfall';

const Home = () => {
  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);
  const [show, setShow] = useState(false);
  const [newsletteremail, setNewsletteremail] = useState("")
  const [loader,setLoader] = useState(false);
  const currencycode = useSelector((state) => state.persistedReducer.home.currencycode)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      // disable: 'mobile'
    });

    setTimeout(() => {
      handleShow();
    }, 3000);
  }, []);

  const newsletter = (e) => {
    e.preventDefault();
    setLoader(true)
    const data = {
      currency_code: currencycode,
      email: newsletteremail
    }
    axios.post(base_url + '/common/subscribe_newsletter', data, {
      headers: postHeader
    })
    .then(res => {
      if (res.data.status == 1) {
                setLoader(false)
                setNewsletteremail('');
                return swal(res.data.message, "", "success");
              } else {
                console.log("error")
                setLoader(false)
                return swal(res.data.message, "", "error");
            }
        })
        .catch((error) => {
            setLoader(false)
            console.log(error);
        });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails?.title}</title>
        <meta name="description" content={metaDetails?.description}></meta>
        <meta name="keywords" content={metaDetails?.keywords}></meta>
        <link rel="canonical" href= {helmet_url} />
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails?.title} />
        <meta property="og:description" content={metaDetails?.description} />
        <meta property="og:url" content={metaDetails?.url} />
        <meta property="og:site_name" content={client_name?.c_name} />
      </Helmet>
      {/* <div className="al_snowfall" style={{ height: '100%', width: '100%', background: '#282c34', overflow: 'hidden' }}>
        <Snowfall  color="#fff"   snowflakeCount={250}  className="al_snowfall_canvas" />
      </div> */}
      <div className='LandingPageShocase'>
        <BannerSlider />
        <ShopByDiamondShape />
        <ExploreCollection />
        {/* <ExploreHolidayCollection /> */}
        <DesignDiamondRing />
        <ShopRingByStyle />
        {/* <GemstoneGlamour /> */}
        <CustomDesign />
        <ConsultSpecialist />
        <Customer />
        {/* <Insta /> */}
        <SignupTo />
      </div>

      {/* <Modal centered className='desktop-discount-popup' show={show} onHide={handleClose}>
        <Modal.Body>
          <Image src={DesktopPopup} className='d-lg-block d-none' alt='' />
          <Image src={MobilePopup} className='d-lg-none d-block' alt='' />

          <div className='content'>
            <h2>Shine Bright This Holiday!</h2>
            <p>Sign up for our newsletter and take 10% off our Holiday Collection. Celebrate in style with exclusive jewelry!</p>

            <div className='subscribe-form'>
              <form onSubmit={newsletter}>
                <InputGroup className="">
                  <Form.Control type='email' value={newsletteremail} required onChange={(e) => setNewsletteremail(e.target.value)} placeholder="Email address" aria-label="Recipient's username" />
                  <Button type="submit">Subscribe Now</Button>
                </InputGroup>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default Home;